export class PremiumPhotoModel {
  url = ''
  description = ''
}

export class PremiumPlacementModel {
  id = 0
  place_type = 0
  sort: number | null = null
  auto_renew = false
  active_from = ''
  active_to = ''
  price = 0
  price_month = 0
  billing_plan = 0
}

export class PremiumModel {
  id = 0
  title = ''
  name = ''
  address = ''
  active = false
  verified = false
  images: PremiumPhotoModel[] = []
  premium_placements: PremiumPlacementModel[] = []
}

export class PremiumAddModel {
  payment_method = 1
  place_id = 0
  offers: number[] = []
}

export class PremiumAddResponseModel {
  payment_link = ''
  download: number | null = null
}

export class PremiumUpdateInfoModel {
  description = ''
  logoImage: any
  backgroundImage: any
}

export class PremiumUpdateInfoResponseModel {
  status = ''
  
}
