
































































































import { Nullable } from '@/types'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const RoomsStore = namespace('RoomsStore')
import { RoomModel } from '@/modules/MyRoomsPage/models/RoomModel'
import { PremiumAddModel } from '../models/PremiumModel'
import PremiumService from '../PremiumService'

import Room, { RoomInterface } from './Room.vue'
import AddPremiumForm from './AddPremiumForm.vue'
import AddPremiumCheck from './AddPremiumCheck.vue'
import Overlay from '@/components/Overlay.vue'
import Modal from '@/components/Modal.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import Spinner from '@/components/Spinner.vue'
import SelectRoom from './SelectRoom.vue'
import InvoicesService from '@/core/services/InvoicesService'

export interface AddPremiumInterface {
  paymentMethod: number
  roomId: Nullable<number>
  planId: Nullable<number>
  offers: number[]
}

@Component({
  name: 'AddPremium',
  components: {
    Overlay,
    Modal,
    BaseIcon,
    BaseButton,
    Spinner,
    Room,
    AddPremiumForm,
    AddPremiumCheck,
    SelectRoom,
  },
})
export default class AddPremium extends Vue {
  @Ref() readonly download!: HTMLAnchorElement

  @Prop({ default: null })
  roomId!: Nullable<number>

  @Prop({ default: null })
  planId!: Nullable<number>

  showAddPremium = true
  showSelectRoom = false
  showErrorModal = false
  errorMessage = ''
  loading = false
  step = 1
  model: AddPremiumInterface = {
    paymentMethod: 1,
    roomId: null,
    planId: null,
    offers: [],
  }

  @RoomsStore.Getter
  public roomList!: RoomModel[]

  @RoomsStore.Action
  private getRooms!: () => Promise<RoomModel[]>

  get room(): Nullable<RoomInterface> {
    return this.roomList.find((room) => room.id === this.model.roomId) || null
  }

  created(): void {
    this.getRooms()
    this.model = {
      paymentMethod: 1,
      roomId: this.roomId,
      planId: this.planId,
      offers: [],
    }
  }

  async submit(): Promise<void> {
    this.loading = true

    const premium = new PremiumAddModel()
    if (this.model.roomId) premium.place_id = this.model.roomId
    premium.payment_method = this.model.paymentMethod
    premium.offers = this.model.offers

    try {
      const response = await PremiumService.premiumAdd(premium)
      if (response?.download) {
        await this.downloadInvoice(response?.download)
      }
      if (response?.payment_link && response.payment_link !== '/premium') {
        this.redirect(response.payment_link)
      } else {
        this.$emit('added')
      }
    } catch (error) {
      this.showAddPremium = false
      this.errorMessage = ''
      if (PremiumService.isServiceError(error)) {
        const errors = error.response?.data?.errors || []
        this.errorMessage = errors[0] || error.message
      }
      this.errorMessage = this.errorMessage || 'Неизвестная ошибка'
      this.showErrorModal = true
    }
  }

  redirect(url: string): void {
    if (typeof url === 'string' && url.startsWith('http')) {
      window.location.href = url
    } else {
      this.$router.push(url)
    }
  }

  async downloadInvoice(id: number): Promise<void> {
    try {
      const blob = await InvoicesService.downloadInvoice(id)
      const url = window.URL.createObjectURL(blob)
      this.download.href = url
      this.download.click()
    } catch (error) {
      console.log(error)
      this.errorHandler(error)
    }
  }

  errorHandler(error: unknown): void {
    this.errorMessage = ''
    if (InvoicesService.isServiceError(error)) {
      const errors = error.response?.data?.errors || []
      this.errorMessage = errors[0] || error.message
    }
    this.errorMessage = this.errorMessage || 'Неизвестная ошибка'
    this.showErrorModal = true
  }
}
